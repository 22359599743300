import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { sharedButtonStyles } from 'styles/Typography.styled';
import { swipe } from 'shared/animations/animations';

export const Wrapper = styled.div(
    ({ theme: { colors, gradients, fontFamily } }) =>
        css`
            margin-top: 120px;

            .btn-apply span {
                ${sharedButtonStyles};
                background: ${gradients.bluePurple};
                color: ${colors.white};
                font-family: ${fontFamily.primary};
                margin: 0 auto;

                &:hover {
                    color: ${colors.mainBlue};
                }
            }
        `,
);

export const StyledLink = styled(Link)`
    height: auto;
    :hover,
    :active {
        text-decoration: inherit;
        color: inherit;
    }
`;

export const HiddenOffers = styled.div`
    position: absolute;
    pointer-events: none;
    visibility: hidden;
`;

export const OfferWrapper = styled.div`
    margin-top: -85px;
`;

export const LinkWrapper = styled(Link)(
    ({ theme: { colors, fontSizes } }) =>
        css`
            align-items: center;
            color: ${colors.grey};
            display: flex;
            font-size: ${fontSizes.f14};

            .arrow {
                margin-left: -12px;
            }
            &:hover {
                .arrow {
                    animation: ${swipe} 0.6s ease-in-out infinite;
                }
            }
        `,
);
