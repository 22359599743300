import { keyframes } from 'styled-components';

export const swipe = keyframes`
  50% {
    opacity: 1;
    transform: translateX(-10px);
  }
`;

export const circleAnimation = keyframes`
    0%, 100% {
        height: 40px;
        transform: translate(0, 0);
        width: 40px;
    }
    50% {
        height: 24px;
        transform: translate(8px, 8px);
        width: 24px;
    }
`;

export const titleAnimation = keyframes`
    50% {
        transform: translateY(-30px);
    }
`;

export const titleAnimationVariants = {
    y: [0, -30, 0],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const descriptionAnimation = keyframes`
    50% {
        transform: translateY(-14px);
    }
`;

export const servicesItemTitleAnimation = keyframes`
    50% {
        transform: translateY(-8px);
    }
`;

export const servicesItemTitleVariants = {
    y: [0, -8, 0],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const servicesItemIconVariants = {
    scale: [1, 1.2, 1],
    y: [0, -12, 0],
    x: [0, 4, 0],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const iconScaleAndGoTopAnimation = keyframes`
    50% {
        transform: scale(1.2) translateY(-12px);
    }
`;

export const orangeButtonAnimation = keyframes`
    50% {
        color: #2D0093;
    }
    100% {
        color: #fff;
    }
`;

export const blueButtonAnimation = keyframes`
    0% {
        background: #FFFFFF;
        color: #2D00FF;
    }
    50% {
        width: 220px;
    }
    100% {
        background: #6DD2FF;
        color: #FFFFFF;
        width: 200px;
    }
`;
export const blueButtonAnimationReverse = keyframes`
    0% {
        background: #6DD2FF;
        color: #FFFFFF;
        width: 200px;
    }
    50% {
        width: 220px;
    }
    100% {
        background: #FFFFFF;
        color: #2D00FF;
    }
`;

export const homeHeroItemVariants = {
    bottom: [-50, -28, -50],
    right: [130, 120, 130],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const homeHeroItemMdVariants = {
    bottom: [-50, -28, -50],
    right: [70, 60, 70],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const homeHeroItemXsVariants = {
    bottom: [-10, 10, -10],
    right: [0, -10, 0],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};

export const homeHeroTitleAnimation = keyframes`
    50% {
        transform: translateY(-28px);
    }
`;

export const homeHeroDescriptionAnimation = keyframes`
    50% {
        transform: translateY(-14px);
    }
`;

export const homeHeroDescriptionVariants = {
    y: [0, -14, 0],
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 1,
    },
};
