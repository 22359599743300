import React, { useEffect } from 'react';
import { ArrowLeftBlue } from 'shared/svg/arrows';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import {
    HiddenOffers,
    LinkWrapper,
    OfferWrapper,
    Wrapper,
} from './SinglePosition.styled';

export const SinglePosition = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://codahead.elevato.net/services/scripts/ElevatoWidgetJobOffers.js?WidgetId=6&WidgetGuid=FBB75BBB-59BF-45D6-A8A6-33607E70EE80';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <Wrapper>
            <Container>
                <Row>
                    <Col lg={12}>
                        <LinkWrapper to={'/career#open-positions'}>
                            <ArrowLeftBlue />
                            open positions
                        </LinkWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <HiddenOffers>
                            <div id="elevato-widget" />
                        </HiddenOffers>
                        <OfferWrapper>
                            <div id="externaldetail" />
                        </OfferWrapper>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};
