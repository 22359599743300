import { CommonMargin } from 'components/_layout/CommonMargin';
import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { PerksAndBenefits } from 'sections/Career/PerksAndBenefits/PerksAndBenefits';
import { SinglePosition } from 'sections/Career/SinglePosition/SinglePosition';

export default () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js',});var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-WV2ZHD9');`;
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <CommonMargin>
            <Fade>
                <SinglePosition />
            </Fade>
            <Fade>
                <PerksAndBenefits />
            </Fade>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-WV2ZHD9"
                height="0"
                width="0"
                loading="lazy"
                style={{ display: 'none', visibility: 'hidden' }}
            />
        </CommonMargin>
    );
};
