import React from 'react';
interface ArrowIconProps {
    width: string;
    height: string;
    stroke: string;
}

export const ArrowDown = ({ width, height, stroke }: ArrowIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M18 10L13.0606 14.5932C12.4773 15.1356 11.5227 15.1356 10.9394 14.5932L6 10"
                stroke={stroke}
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ArrowLeftBlue = () => {
    return (
        <svg
            className="arrow"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <path
                d="M25 30L15 20L25 10"
                stroke="#6DD2FF"
                stroke-width="5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ScrollUpArrowIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
                d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56824 3.03617 8.69181 1.52242 12.3463C0.00866568 16.0009 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9992 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0ZM27 24.83L20 17.83L13 24.83L10.17 22L20 12.17L29.83 22L27 24.83Z"
                fill="url(#paint0_linear_3619_15443)"
                fill-opacity="0.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3619_15443"
                    x1="20"
                    y1="-9"
                    x2="20"
                    y2="50.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#6CCCFF" />
                    <stop offset="1" stop-color="#6CCCFF" stop-opacity="0.41" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const OrangeArrowIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#FF7E19"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke-width="2"
                d="M3.333 8h9.334M8 3.333L12.667 8 8 12.667"
            />
        </svg>
    );
};
